<template>
    <div class='CreateAndEdit'>
        <el-card class="box-card">
            <div>
                <i class="el-icon-close cha" @click="$router.go(-1)"></i>
                <div class="clearfix">
                    装备包出库
                </div>
            </div>
            <el-divider ></el-divider>
            <div class="formbox">
                <el-form :model="equipInfo" ref="equipInfo">
                    <!-- <el-form-item label="编号" :label-width="formLabelWidth" prop="id">
                        <el-input v-model="equipInfo.id" autocomplete="off" :readonly="state"></el-input>
                    </el-form-item> -->
                    <el-form-item label="装备包名称" :label-width="formLabelWidth" prop="bagName">
                        <el-input v-model="equipInfo.bagName" autocomplete="off" :readonly="state"></el-input>
                    </el-form-item>
                    <el-form-item label="工作内容" :label-width="formLabelWidth" prop="workContent">
                        <el-input v-model="equipInfo.workContent" autocomplete="off" :readonly="state"></el-input>
                    </el-form-item>
                    <el-form-item label="工作日期" :label-width="formLabelWidth" prop="workDate">
                        <el-date-picker v-model="equipInfo.workDate" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                            type="date" placeholder="选择日期" :readonly="state">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="经办人" :label-width="formLabelWidth" prop="workJbr">
                        <el-input v-model="equipInfo.workJbr" autocomplete="off" :readonly="state"></el-input>
                    </el-form-item>
                    <el-form-item label="装备包状态" :label-width="formLabelWidth" prop="status_text">
                        <el-input v-model="equipInfo.status_text" autocomplete="off" :readonly="state"></el-input>
                    </el-form-item>
                </el-form>
                <h1>装备包仪器仪表</h1>
                <el-divider ></el-divider>

                <!-- 仪器列表 -->
                <el-table :data="deviceInfoList" border style="width: 100%; font-size: 13px;"
                    :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                    <el-table-column prop="seq" label="序号" align="center">
                    </el-table-column>
                    <el-table-column prop="deviceOneClassDesc" label="仪器分类" align="center"> </el-table-column>
                    <el-table-column prop="deviceName" label="仪器名称" align="center">
                    </el-table-column>
                    <el-table-column prop="deviceRealName" label="实际名称" align="center">
                    </el-table-column>
                    <el-table-column prop="deviceNum" label="仪器编号" align="center">
                    </el-table-column>
                    <el-table-column prop="deviceType" label="仪器型号" align="center">
                    </el-table-column>
                    <el-table-column prop="deviceStatus_text" label="仪器状态" align="center">
                    </el-table-column>
                    <el-table-column prop="location" label="保存地点" align="center">
                    </el-table-column>
                </el-table>

            </div>
            <div>出库信息</div>
            <el-divider></el-divider>
            <div class="ckInfo">
                <el-form :model="deviceCkInfo" :rules="rules" ref="deviceCkInfo">
                    <el-form-item label="试验日期" :label-width="formLabelWidth" prop="testDate">
                        <el-date-picker v-model="deviceCkInfo.testDate" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                            type="date" placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="站所" :label-width="formLabelWidth" prop="station">
                        <el-input v-model="deviceCkInfo.station" autocomplete="off" class="input"></el-input>
                    </el-form-item>
                    <el-form-item label="间隔" :label-width="formLabelWidth" prop="intervalDesc">
                        <el-input v-model="deviceCkInfo.intervalDesc" autocomplete="off" class="input"></el-input>
                    </el-form-item>
                    <el-form-item label="试验类别" :label-width="formLabelWidth" prop="testType">
                        <el-input v-model="deviceCkInfo.testType" autocomplete="off" class="input"></el-input>
                    </el-form-item>
                    <el-form-item label="试验性质" :label-width="formLabelWidth" prop="testNature">
                        <el-input v-model="deviceCkInfo.testNature" autocomplete="off" class="input"></el-input>
                    </el-form-item>
                    <el-form-item label="使用期限" :label-width="formLabelWidth" prop="useDeadline">
                        <el-date-picker v-model="deviceCkInfo.useDeadline" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                            type="date" placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="试验负责人" :label-width="formLabelWidth" prop="testFzr">
                        <el-input v-model="deviceCkInfo.testFzr" autocomplete="off" class="input"></el-input>
                    </el-form-item>
                    <el-form-item label="试验人员" :label-width="formLabelWidth" prop="tester">
                        <el-input v-model="deviceCkInfo.tester" autocomplete="off" class="input"></el-input>
                    </el-form-item>
                    <el-form-item label="出库经办人" :label-width="formLabelWidth" prop="ckJbr">
                        <el-input v-model="deviceCkInfo.ckJbr" autocomplete="off" class="input"></el-input>
                    </el-form-item>
                    <el-form-item class="df" :label-width="formLabelWidth">
                        <el-button class="btn" @click="sure('deviceCkInfo')">确 定</el-button>
                        <!-- <el-button @click="closeInfo('deviceCkInfo')">取 消</el-button> -->
                    </el-form-item>
                </el-form>
            </div>

        </el-card>
    </div>
</template>

<script>
import { queryEquipBag, ckInventory } from '@/api/deviceEquipbag.js'
import { deviceStatusList } from '@/api/device.js'
import { deviceCKRules } from '@/api/deviceCrk.js'  //ckInventory
export default {
    props: {
        id: {
            type: [String, Number]
        }
    },
    data() {
        return {
            deviceStatusList: deviceStatusList,
            deviceInfoList: [],
            equipInfo: {
                id: '',
                bagName: null,
                workContent: null,
                workDate: null,
                workJbr: null,
                deviceContent: null,
                deviceList: [],
                deviceNumber: 0,
                status: '00',
                createTime: null,
                ckJbr: null
            },
            formLabelWidth: "100px",
            state: this.id ? true : false,
            deviceCkInfo: {
                deviceInfoList: [],
                id: this.id,
                crkType: '00',
                testDate: null,
                testType: null,
                station: null,
                intervalDesc: null,
                testNature: null,
                useDeadline: null,
                testFzr: null,
                tester: null,
            },
            rules: deviceCKRules,
        }
    },
    mounted() {
        this.loadEquipBagInfo()

        console.log('this.id')
        console.log(this.id)
        console.log(this.deviceCkInfo)
        console.log(this.deviceCkInfo)
    },
    methods: {
        async loadEquipBagInfo() {
            await queryEquipBag(this.id).then(res => {
                if (res.code === '000000') {
                    this.equipInfo = res.t
                    this.equipInfo.status === '00' ? (this.equipInfo.status_text = '正常') : (this.equipInfo.status_text = '失效')
                    this.deviceInfoList = res.t.deviceList
                    let count = 1
                    this.deviceInfoList.forEach(item => {
                        item.seq = count++
                        const index = this.deviceStatusList.findIndex(item2 => item2.code === item.deviceStatus)
                        if (index !== -1) {
                            const { name } = this.deviceStatusList[index]
                            item.deviceStatus_text = name
                        }
                    })
                }
            })
        },
        //提交出库单
        sure(deviceCkInfo) {
            console.log("deviceCkInfo")
            console.log(deviceCkInfo)
            
            this.$refs[deviceCkInfo].validate(valid => {
                if (valid) {
                    this.$fullScreenLoading.show("出库中...");
                    this.deviceCkInfo.deviceInfoList = this.deviceInfoList
                    // this.deviceCkInfo.eid =this.equipInfo.id
                    ckInventory(this.deviceCkInfo).then(res => {

                        setTimeout(() => {
                            if (res.code === '000000') {
                                this.$message.success('出库成功')
                            }

                            this.$refs[deviceCkInfo].resetFields()
                            this.deviceInfoList = []
                            this.$fullScreenLoading.hide();
                            this.$router.go(-1)
                        }, 500)
                    })

                }
            })
        },



    },
}
</script>
<style lang="scss" scoped>
.CreateAndEdit {
    .el-card {
        position: relative;
        text-align: left;

        .clearfix {
            text-align: left;
        }

        .cha {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 30px;
            color: rgba(54, 55, 56, 0.445);
        }

        .cha:hover {
            cursor: pointer;
            color: #2a2d2f;
        }
    }

    .el-form-item {
        width: 500px;
    }

    .df {
        text-align: left;
    }

    .deviceForm {
        margin-bottom: 10px;

        .deviceSelecct {
            width: 30%;
        }
    }

    .el-table {
        margin-bottom: 30px;
    }

    .dialog-footer {
        text-align: right;
    }
}
</style>